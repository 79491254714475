.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.input-group {
  width: 30% !important;
}
.pl-5 {
  margin-left: -70px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.code {
    font-size: 1.875em;
    color: #d63384;
    word-wrap: break-word;
}

.banner{	
  background-image:url(./assets/banner.png);
	background-repeat: no-repeat;
	background-size: 1600px 480px;
	width:100%;
	height: 572px;
	background-position:bottom;
	
}

.banner-content{
	padding:20px 40px 20px 20px;

}

.banner-content h2{
	margin-bottom:40px;
	line-height:1.5em;
	
}

#footer{
	background: #222222;
	padding: 5px 0 0;
	overflow:hidden;
}

#footer p{
	text-align:center;
	color:#999999;
	font-size:12px;
	margin-bottom:5px;
	
}

.planinput {
  background-color: #E9EEF4 !important;
  border-radius: 8px !important;
  border: 0 !important;
  font-size: 16px;
  color: black;
  max-width: 95%;
}